import { Component, Injector } from '@angular/core';
import { BaseDialogComponent, IconName } from '@san/tools';

@Component({
  selector: 'rdv-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BaseDialogComponent {

  IconName: typeof IconName = IconName;

  constructor(protected override injector: Injector) {
    super(injector);
  }

}
