import { Component } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent {


  constructor(private menuService: MenuService, private userService: UserService, private navigationService: NavigationService) { }

  get menu() {
    let menus = this.menuService.menu();
    if (this.userService.medecinIsConnected) {
      menus = this.menuService.medecinMenu();
    } else if (this.userService.patientIsConnected) {
      menus = this.menuService.patientMenu();
    }
    return menus;
  }

  get visibleMenus() {
    return this.menu?.filter(m => m.visible !== false);
  }

  isActivePage(url: string): boolean {
    return url === this.navigationService.currentRouter.url;
  }

}
