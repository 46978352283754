import { UserService } from './../../../services/user.service';
import { Component, Injector } from '@angular/core';
import { BaseDialogComponent, IconName } from '@san/tools';
import { Patient } from './../../../models/entity/patient';

@Component({
  selector: 'rdv-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent extends BaseDialogComponent {

  patient: Patient;
  IconName = IconName;

  constructor(protected override injector: Injector, private userService: UserService) {
    super(injector);
    this.patient = this.data?.content;
  }

  get editable() {
    return this.userService.userIsConnected;
  }
}
