import { Component, Injector } from '@angular/core';
import { BaseDialogComponent, IconName } from '@san/tools';
import { RdvService } from '../../api/rdv.service';
import { Rdv } from '../../models/entity/rdv';
import { RdvType, StatusRdv } from '../../models/enum/rdv.enum';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-detail',
  templateUrl: './rdv-detail.component.html',
  styleUrls: ['./rdv-detail.component.scss']
})
export class RdvDetailComponent extends BaseDialogComponent {
  isMedecin = this.userService.medecinIsConnected;
  rdv: Rdv;
  IconName = IconName;
  StatusRdv = StatusRdv;

  constructor(protected override injector: Injector, private rdvService: RdvService, private userService: UserService) {
    super(injector);
    this.rdv = this.data?.content?.rdv;
  }

  get title() {
    return this.isRdv ? 'rdv.meeting' : 'rdv.event';
  }

  get isRdv() {
    return !!this.rdv?.patient?.id || !this.isMedecin;
  }

  get pending() {
    return this.rdv?.status === StatusRdv.PENDING;
  }

  get canCancel() {
    const status = this.isMedecin ? StatusRdv.IN_PROGRESS : StatusRdv.PENDING;
    return [StatusRdv.ACCEPTED, status].includes(this.rdv?.status);
  }

  get canEdit() {
    return this.rdv?.status === StatusRdv.ACCEPTED && this.isMedecin;
  }

  editStatus(status: StatusRdv) {
    this.rdvService.editStatus(this.rdv, status, this.isRdv ? RdvType.RDV : RdvType.EVENT, () => this.close({ content: true }));
  }

  edit() {
    this.close({ content: this.rdv });
  }

  delete() {
    this.rdvService.deleteEvent(RdvType.EVENT, this.rdv, () => this.close({ content: true }));
  }

}
