import { Injectable } from "@angular/core";
import { IconName, MenuItem } from "@san/tools";
import { BaseMenu, MededinMenu, PatientMenu } from "../models/enum/user-menu.enum";
import { AccountType } from "../models/enum/user-shared.enum";

@Injectable({ providedIn: 'root' })
export class MenuService {

  menu(): MenuItem[] {
    return [
      {
        id: 1,
        label: 'menu.accueil',
        link: `${BaseMenu.HOME}`,
        visible: true,
        icon: IconName.HOME
      },
      {
        id: 2,
        label: 'menu.medecins',
        link: `/${BaseMenu.HOME}/${BaseMenu.DOCTOR}`,
        visible: true,
        icon: IconName.DOCTOR
      },
      {
        id: 3,
        label: 'menu.mon-compte',
        link: `/account/${AccountType.PATIENT}/login`,
        visible: true,
        icon: IconName.USER
      }
    ];
  }

  medecinMenu(): MenuItem[] {
    const base = `/${BaseMenu.MEDECIN}`;
    return [
      { id: MededinMenu.SYNTHESE, label: 'menu.synthese', link: `${base}/${MededinMenu.SYNTHESE}`, icon: IconName.DISPLAY },
      { id: MededinMenu.CALENDRIER, label: 'menu.medecin.calendrier', link: `${base}/${MededinMenu.CALENDRIER}`, icon: IconName.CALENDAR },
      { id: MededinMenu.CALENDRIER, label: 'menu.rdv', link: `${base}/${MededinMenu.RDV}`, icon: IconName.TIME },
      { id: MededinMenu.PATIENTS, label: 'menu.medecin.patients', link: `${base}/${MededinMenu.PATIENTS}`, icon: IconName.USER_GROUP },
      { id: MededinMenu.PAYEMENTS, label: 'menu.paiements', link: `${base}/${MededinMenu.PAYEMENTS}`, icon: IconName.FILE_INVOICE },
      { id: MededinMenu.STATS, label: 'menu.stats', link: `${base}/${MededinMenu.STATS}`, icon: IconName.CHART_PIE },
      { id: MededinMenu.PROFILE, label: 'menu.profile', link: `${base}/${MededinMenu.PROFILE}`, icon: IconName.DOCTOR },
      { id: MededinMenu.SETTING, label: 'menu.parametrage', link: `${base}/${MededinMenu.SETTING}`, icon: IconName.CONFIG }
    ];
  }

  patientMenu(): MenuItem[] {
    const base = `/${BaseMenu.PATIENT}`;
    return [
      { id: PatientMenu.SYNTHESE, label: 'menu.synthese', link: `${base}/${PatientMenu.SYNTHESE}`, icon: IconName.DISPLAY },
      { id: PatientMenu.RDV, label: 'menu.rdv', link: `${base}/${PatientMenu.RDV}`, icon: IconName.CALENDAR },
      { id: PatientMenu.PAYEMENTS, label: 'menu.paiements', link: `${base}/${PatientMenu.PAYEMENTS}`, icon: IconName.FILE_INVOICE },
      { id: PatientMenu.PROFILE, label: 'menu.profile', link: `${base}/${PatientMenu.PROFILE}`, icon: IconName.USER_TIE }
    ];
  }

}
