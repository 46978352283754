<div *ngIf="medecin" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div>
    <rdv-photo [user]="medecin" [isMedcin]="true" [editable]="editable"></rdv-photo>
    <div class="info small-padding">
      <div><label>{{'account.name' | translate}}</label> : <span>{{medecin.nom | displayValue}}</span></div>
      <div><label>{{'account.firstname' | translate}}</label> : <span>{{medecin.prenoms | displayValue}}</span></div>
      <div><label>{{'account.sexe' | translate}}</label> : <span>{{medecin.sexe | genre}}</span></div>
      <ng-container *ngIf="editable">
        <div><label>{{'account.email' | translate}}</label> : <span>{{medecin.email | displayValue}}</span></div>
        <div><label>{{'account.date-naissance' | translate}}</label> :
          <span>{{medecin.date_naissance | displayDate}}</span>
        </div>
        <div><label>{{'account.lieu-naissance' | translate}}</label> :
          <span>{{medecin.lieu_naissance | displayValue}}</span>
        </div>
        <div><label>{{'account.portable' | translate}}</label> : <span>{{medecin.portable | displayValue}}</span></div>
        <div><label>{{'account.telephone' | translate}}</label> : <span>{{medecin.telephone | displayValue}}</span>
        </div>
        <div><label>{{'account.contact' | translate}}</label> : <span>{{medecin.contact | displayValue}}</span></div>
      </ng-container>
      <div><label>{{'account.city' | translate}}</label> : <span>{{medecin.ville?.libelle | displayValue}}</span></div>
      <div><label>{{'account.commune' | translate}}</label> : <span>{{medecin.commune?.libelle | displayValue}}</span>
      </div>
      <div *ngIf="editable"><label>{{'account.adresse' | translate}}</label> : <span>
          {{medecin.adresse | displayValue}}</span></div>
    </div>
  </div>
</div>