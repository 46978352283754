<div *ngIf="etablissement" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div class="info small-padding">
    <div><label>{{'account.name' | translate}}</label> : <span>{{etablissement?.libelle| displayValue}}</span></div>
    <div><label>{{'account.email' | translate}}</label> : <span>{{etablissement?.email | displayValue}}</span></div>
    <div><label>{{'account.telephone' | translate}}</label> :
      <span>{{etablissement?.telephone | displayValue}}</span>
    </div>
    <div><label>{{'account.city' | translate}}</label> :
      <span>{{etablissement?.ville?.libelle | displayValue}}</span>
    </div>
    <div><label>{{'account.commune' | translate}}</label> :
      <span>{{etablissement?.commune?.libelle | displayValue}}</span>
    </div>
    <div><label>{{'account.adresse' | translate}}</label> : <span>{{etablissement?.adresse | displayValue}}</span></div>
  </div>
</div>