<div class="medecin-resume" class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px"
  *ngIf="medecin">
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="5px">
    <img alt="Photo" class="user-photo picture" rdvImageLoader
      [src]="medecin.photo | previewFile:medecin.id:FileDestination.MEDECIN">
    <div class="info small-padding">
      <div><label>{{'account.name' | translate}}</label> : <span class="font-bold">{{medecin | fullName}}</span>
      </div>
      <div><label>{{'profil-medecin.etablissement' | translate}}</label> :
        <span>{{medecin?.etablissement?.libelle|displayValue}}</span>
      </div>
      <div><label>{{'profil-medecin.edit-etablissement.ville' | translate}}</label> :
        <span>
          {{medecin?.etablissement?.ville?.libelle | displayValue}} /
          {{medecin?.etablissement?.commune?.libelle|displayValue}}
        </span>
      </div>
      <div><label>{{'profil-medecin.specialites' | translate}}</label> :
        <span>{{medecin.specialites|displayList}}</span>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="full-width" fxLayoutAlign="end start">
    <san-button-icon id="profil-medecin-btn-{{medecin.id}}" label="{{'profil-medecin.afficher-profil' | translate}}"
      color="accent" [icon]="IconName.EYE" [borderless]="true" (onclick)="openProfile()"></san-button-icon>

    <san-button-icon *ngIf="showRdvButton" id="rdv-medecin-btn-{{medecin.id}}" label="{{'rdv.meeting' | translate}}"
      color="accent" [borderless]="true" [icon]="IconName.TIME" (onclick)="openBooking()"></san-button-icon>
  </div>
</div>