import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from './../../shared/models/dto/response.dto';
import { StatusCode } from './../../shared/models/dto/status-code.model';
import { NotificationService } from './../../shared/services/notifaction.service';
import { DOWNLOAD_REQUEST_CONTEXT, InterceptorException } from './../enum/core.enum';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tab: string[] = req.url.split('?');
    if (tab.length > 0) {
      const urlWithoutParameters = tab[0];
      if (!InterceptorException.some(it => urlWithoutParameters.endsWith(it))) {
        return next.handle(req).pipe(tap(resp => {
          if (resp instanceof HttpResponse && !this.excludedContexts(req) && (resp?.body as ApiResponse<unknown>)?.status !== StatusCode.OK) {
            const rep = (resp?.body as ApiResponse<unknown>);
            const sms = rep?.status === StatusCode.CUSTOM_ERROR ? rep.message : null;
            this.notificationService.handleResponse((resp?.body as ApiResponse<unknown>)?.status, sms, false);
          }
        }), catchError(error => {
          this.notificationService.error('Une erreur technique est survenue, merci de réessayer plus tard.');
          this.updateLoading();
          return throwError(() => new Error(error));
        }));
      }
    }
    return next.handle(req);
  }

  updateLoading() {
    const loader = document.getElementById('splash-loader');
    const message = document.getElementById('splash-text');
    const error = document.getElementById('splash-error');
    if (loader) {
      loader.style.display = 'none';
    }
    if (message) {
      message.style.display = 'none';
    }
    if (error) {
      error.style.display = 'block';
    }
  }

  private excludedContexts(req: HttpRequest<unknown>): boolean {
    return req.context.get(DOWNLOAD_REQUEST_CONTEXT) === true;
  }
}
