import { Component, Input } from '@angular/core';
import { Etablissement } from '../../models/entity/etablissement';

@Component({
  selector: 'rdv-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent {
  @Input() etablissement: Etablissement;

}
