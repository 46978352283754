import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName } from '@san/tools';
import { RdvService } from '../../api/rdv.service';
import { Rdv } from '../../models/entity/rdv';
import { RdvType, StatusRdv } from '../../models/enum/rdv.enum';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  @Input() rdv: Rdv;
  @Output() rdvChange: EventEmitter<void> = new EventEmitter<void>();
  IconName = IconName;
  StatusRdv = StatusRdv;
  isMedecin = this.userService.medecinIsConnected;

  constructor(private rdvService: RdvService, private userService: UserService) { }

  get user() {
    return this.isMedecin ? this.rdv?.patient : this.rdv?.medecin;
  }

  get isRdv() {
    return !!this.rdv?.patient?.id || !this.isMedecin;
  }

  get pending() {
    return this.rdv?.status === StatusRdv.PENDING;
  }

  get canCancel() {
    if (!this.isMedecin) {
      return [StatusRdv.ACCEPTED, StatusRdv.PENDING].includes(this.rdv?.status);
    } else {
      return [StatusRdv.ACCEPTED, StatusRdv.IN_PROGRESS].includes(this.rdv?.status);
    }
  }

  get canEdit() {
    return ![StatusRdv.PENDING, StatusRdv.REJECTED, StatusRdv.CANCELED].includes(this.rdv?.status) && this.isMedecin;
  }

  showRdv(rdv: Rdv) {
    this.rdvService.openDetailsDialog(rdv, () => this.rdvChange.next());
  }

  editStatus(status: StatusRdv) {
    this.rdvService.editStatus(this.rdv, status, this.isRdv ? RdvType.RDV : RdvType.EVENT, () => this.rdvChange.next());
  }

  delete() {
    this.rdvService.deleteEvent(RdvType.EVENT, this.rdv, () => this.rdvChange.next());
  }

  edit() {
    this.rdvService.openRdvDialog(null, this.rdv, null, () => this.rdvChange.next());
  }
}
