import { Pipe, PipeTransform } from "@angular/core";
import { StatusRdv } from "../models/enum/rdv.enum";
import { TraductorService } from "../services/traductor.service";

@Pipe({ name: 'rdvStatus' })
export class RdvStatusPipe implements PipeTransform {

  constructor(private traductor: TraductorService) {
  }

  transform(status: StatusRdv): string {
    return this.traductor.translate(`rdv.etat.${status}`);
  }
}
