<div *ngIf="diplomes?.length" class="full-width" [class.disabled]="readonly" fxLayout="column"
  fxLayoutAlign="start center">
  <div class="diplome-item full-width pointer" fxLayout="row" fxLayoutAlign="space-between center"
    *ngFor="let d of diplomes;" (dblclick)="edit(d)">
    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="font-bold">{{d.annee}} : {{d.libelle}}</div>
      <div class="small-font">{{d.etablissement}}</div>
    </div>
    <div *ngIf="!readonly">
      <san-icon [name]="IconName.MENU" color="primary" id="menu-diplome-{{d.id}}" [cdkMenuTriggerFor]="menu.mainMenu"
        [cdkMenuTriggerData]="d" (cdkMenuOpened)="setDiplome(d)" [title]="'shared.modifier' | translate"></san-icon>
    </div>
  </div>
  <san-menu #menu [menuItems]="menus"></san-menu>
</div>