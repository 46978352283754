import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, FileUploadDialogContent, IconName, SanUploadDialogComponent } from '@san/tools';
import { filter, map, take, takeUntil } from 'rxjs';
import { MedecinService } from '../../api/medecin.service';
import { PatientService } from '../../api/patient.service';
import { Medecin } from '../../models/entity/medecin';
import { Patient } from '../../models/entity/patient';
import { AppConf } from '../../models/interfaces/app-conf.interface';
import { FileDestination, FileResponse } from '../../models/interfaces/file.interface';
import { AppconfService } from '../../services/app-conf.service';
import { DialogService } from '../../services/dialog.service';
import { FileService } from '../../services/file.service';
import { ReactiveService } from '../../services/reactive.service';

@Component({
  selector: 'rdv-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent extends BaseComponent {
  @Input() user: Medecin | Patient;
  @Input() isMedcin: boolean = true;
  @Input() editable: boolean = false;
  @Output() photoChange: EventEmitter<string> = new EventEmitter<string>;

  IconName = IconName;
  conf: AppConf;

  constructor(private dialogService: DialogService, private appConfService: AppconfService, private medecinService: MedecinService,
    private patientService: PatientService, private reactiveService: ReactiveService) {
    super();
    this.conf = this.appConfService.getAppConf();
  }

  get destitation() {
    return this.isMedcin ? FileDestination.MEDECIN : FileDestination.PATIENT;
  }

  openEditionModal() {
    this.dialogService.open(SanUploadDialogComponent, {
      data: {
        header: 'shared.photo',
        content: {
          extensions: this.appConfService.imageExtensions,
          uploadFn: (files: File[]) => {
            const data = new FormData();
            data.append('uuid', this.user.id);
            data.append('destination', this.destitation);
            data.append('photo', files[0], files[0].name);
            const ws = this.isMedcin ? this.medecinService.updatePhoto(data) : this.patientService.updatePhoto(data);
            return ws.pipe(filter(FileService.filterUploadResponse));
          }
        } as FileUploadDialogContent
      }
    }).closed.pipe(
      takeUntil(this.destroy),
      take(1),
      filter(res => !!res?.content),
      map(res => res.content?.response?.data as FileResponse)
    ).subscribe(res => {
      this.user.photo = res.files[0].url;
      this.photoChange.next(this.user.photo);
    });
  }


  deletePhoto() {
    if (this.user.photo?.length) {
      const ws = this.isMedcin ? this.medecinService.deletePhoto(this.user.id) : this.patientService.deletePhoto(this.user.id);
      this.reactiveService.call(ws.pipe(take(1)), () => {
        this.user.photo = null;
        this.photoChange.next(this.user.photo);
      }, true);
    }
  }
}
