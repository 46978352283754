import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { ApiResponse } from '../models/dto/response.dto';
import { StatusCode } from '../models/dto/status-code.model';
import { AppConf } from '../models/interfaces/app-conf.interface';
import { FileResponse } from '../models/interfaces/file.interface';
import { environment } from './../../../environments/environment';
import { AppconfService } from './app-conf.service';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  conf: AppConf;

  constructor(private appConfService: AppconfService) {
    this.conf = this.appConfService.getAppConf();
  }

  /**
   * Operation de transformation du buffer en base64
   * @param buffer
   * @returns
   */
  static bufferToBase64(buffer: any): string {
    if (buffer?.data) {
      const data = new Uint8Array(buffer.data);
      return CryptoService.toBase64(data.reduce((elt, byte) => elt + String.fromCharCode(byte), ''));
    }
    return null;
  }

  static filterUploadResponse(response: ApiResponse<FileResponse>): boolean {
    return response.status === StatusCode.OK && response.data?.files.length > 0;
  }

  public getBaseUrl() {
    if (!environment.production) {
      return environment.serverUrl;
    }
    return this.conf.apiUrl;
  }

  /**
   * Decode le buffer en base64 pour affichage
   * @param buffer
   * @returns
   */
  static decodeFile(buffer: Buffer): string {
    const b64 = FileService.bufferToBase64(buffer);
    return b64 ? `data:image/png;base64,${b64}` : null;
  }

  public static convertSize(bytes: number): string {
    return (bytes / 1048576).toFixed(2);
  }

  static saveFile(response: ApiResponse<FileResponse>) {
    const files = response.data?.files;
    if (files?.length) {
      files.forEach(f => saveAs(new Blob([f.data]), f.url));
    }
  }
}
