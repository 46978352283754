import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent, FileUploadDialogContent, IconName, MenuItem, SanUploadDialogComponent, SortDirection } from '@san/tools';
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { MedecinDiplomeDialogComponent } from '../medecin-diplome-dialog/medecin-diplome-dialog.component';
import { DiplomeService } from './../../../api/diplome.service';
import { Diplome } from './../../../models/entity/diplome';
import { FileDestination, FileResponse } from './../../../models/interfaces/file.interface';
import { AppconfService } from './../../../services/app-conf.service';
import { DialogService } from './../../../services/dialog.service';
import { FileService } from './../../../services/file.service';
import { NotificationService } from './../../../services/notifaction.service';
import { TraductorService } from './../../../services/traductor.service';
import { ObjectUtility } from './../../../utils/object.utils';

@Component({
  selector: 'rdv-medecin-diplome',
  templateUrl: './medecin-diplome.component.html',
  styleUrls: ['./medecin-diplome.component.scss']
})
export class MedecinDiplomeComponent extends BaseComponent implements OnChanges {

  @Input() diplomes: Diplome[] = [];
  @Input() readonly: boolean = false;

  IconName = IconName;
  currentDiplome: Diplome = null;

  menus: MenuItem[] = [
    { id: 0, label: 'shared.modifier', click: () => this.updateDiplome() },
    { id: 1, label: 'profil-medecin.ajouter-diplome', click: () => this.editDiplomeFile() },
    { id: 2, label: 'shared.telecharger', visible: false, click: () => this.download() },
    { id: 3, label: 'profil-medecin.supprimer-diplome', visible: false, click: () => this.deleteFile() },
    { id: 4, label: 'shared.supprimer', click: () => this.deleteDiplome() }
  ];

  constructor(private diplomeService: DiplomeService, private reactiveService: ReactiveService,
    private dialogService: DialogService, private appConfService: AppconfService, private notificationService: NotificationService,
    private traductor: TraductorService) {
    super();

    DiplomeService.addDiplome$.pipe(debounceTime(500), takeUntil(this.destroy)).subscribe(() => {
      this.currentDiplome = null;
      this.updateDiplome();
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diplomes) {
      this.refresh();
    }
  }

  edit(diplome: Diplome) {
    if (!this.readonly) {
      this.currentDiplome = diplome;
      this.updateDiplome();
    }
  }

  setDiplome(diplome: Diplome) {
    this.currentDiplome = diplome;
    const visible = diplome?.fichier?.length > 0;
    this.menus[2].visible = visible;
    this.menus[3].visible = visible;
  }

  updateDiplome() {
    this.dialogService.open(MedecinDiplomeDialogComponent, {
      disableClose: true,
      data: {
        content: this.currentDiplome
      }
    }).closed.pipe(takeUntil(this.destroy), filter(res => !!res?.content), map(res => res.content as Diplome))
      .subscribe(diplome => {
        this.diplomes = ObjectUtility.updateList(this.diplomes, diplome, 'id');
        this.refresh()
      });
  }

  editDiplomeFile() {
    this.dialogService.open(SanUploadDialogComponent, {
      data: {
        header: 'profil-medecin.edit-diplome.title',
        content: {
          extensions: this.appConfService.extensions,
          withPreview: false,
          maxSize: 2,
          totalSize: 2,
          uploadFn: (files: File[]) => {
            const data = new FormData();
            data.append('uuid', this.currentDiplome.id.toString());
            data.append('destination', FileDestination.DIPLOME);
            data.append('fichier', files[0], files[0].name);
            return this.diplomeService.editFile(data).pipe(filter(FileService.filterUploadResponse),
              tap(res => this.notificationService.handleResponse(res.status, null, true)));
          }
        } as FileUploadDialogContent
      }
    }).closed.pipe(takeUntil(this.destroy), filter(res => !!res?.content), map(res => res.content?.response?.data as FileResponse))
      .subscribe(res => this.currentDiplome.fichier = res.files[0].url);
  }

  download() {
    this.diplomeService.downloadFile(`file/${this.currentDiplome.id?.toString()}`, this.currentDiplome.fichier);
  }

  deleteFile() {
    this.reactiveService.call(this.diplomeService.deleteFile(this.currentDiplome.id?.toString()).pipe(take(1)), () => this.currentDiplome.fichier = null, true);
  }

  deleteDiplome() {
    if (this.currentDiplome?.id) {
      this.dialogService.confirm('profil-medecin.delete-diplome',
        this.traductor.translate(`profil-medecin.delete-diplome-sms`, { label: this.currentDiplome.libelle }),
        () => {
          this.reactiveService.call(this.diplomeService.delete(this.currentDiplome.id?.toString()).pipe(take(1)), () => {
            this.diplomes = this.diplomes.filter(d => d.id !== this.currentDiplome.id);
            this.currentDiplome = null;
          }, true);
        });
    }
  }

  refresh() {
    this.diplomes = ObjectUtility.sort(this.diplomes, 'annee', SortDirection.DESC);
  }

}
