import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDialogComponent, IconName } from '@san/tools';
import { filter, take } from 'rxjs';
import { CryptoService } from './../../../shared/services/crypto.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'rdv-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent extends BaseDialogComponent implements OnInit {

  IconName: typeof IconName = IconName;
  emailForm: FormGroup;
  user = this.userService.user;

  constructor(protected override injector: Injector, private userService: UserService) {
    super(injector);
  }

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  validate() {
    const { email, password } = this.emailForm.getRawValue();
    if (this.user.email !== email) {
      this.userService.updateEmail(this.userService.userType, { email, password: CryptoService.toBase64(password), id: this.user.id })
        .pipe(take(1), filter(resp => resp.data === true))
        .subscribe(() => {
          this.userService.notificationService.success('core.user.message-change-mail-success', null, { disableTimeOut: true });
          this.close({ content: true });
        });
    } else {
      this.userService.notificationService.warning('core.user.message-change-mail-same');
    }
  }
}
