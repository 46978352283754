import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SanNavigationService } from '@san/tools';
import { Medecin } from '../models/entity/medecin';
import { Rdv } from '../models/entity/rdv';
import { RdvStep } from '../models/enum/rdv.enum';
import { AccountType } from '../models/enum/user-shared.enum';
import { UserType } from '../models/enum/user-type.enum';
@Injectable({
  providedIn: 'root',
})
export class NavigationService extends SanNavigationService {

  constructor(override route: ActivatedRoute, override router: Router) {
    super(route, router);
  }

  static getUserRouteExtra(accountType: AccountType): NavigationExtras {
    return { state: { accountType } };
  }

  get currentRoute() {
    return this.route;
  }

  get currentRouter() {
    return this.router;
  }

  getExtraData(key: string, defaultValue: unknown = null) {
    const state = this.currentRouter.getCurrentNavigation()?.extras?.state;
    return state && state[key] ? state[key] : defaultValue;
  }

  getAccountTypeFromUrl(): AccountType {
    const url = this.currentRouter.url.toLowerCase();
    if (url.includes(`/${AccountType.MEDECIN.toLowerCase()}/`)) {
      return AccountType.MEDECIN;
    } else if (url.includes(`/${AccountType.PATIENT.toLowerCase()}/`)) {
      return AccountType.PATIENT;
    }
    return null;
  }

  getUserType(accountType: AccountType): UserType {
    if (accountType === AccountType.MEDECIN) {
      return UserType.MEDECIN;
    } else if (accountType === AccountType.PATIENT) {
      return UserType.PATIENT;
    }
    return null;
  }

  openInNewTab(url: string, extras?: NavigationExtras) {
    url = this.router.serializeUrl(this.router.createUrlTree([url], extras));
    window.open(url, '_blank');
  }

  openBooking(medecin: Medecin, step: RdvStep = RdvStep.RAISON, request?: Partial<Rdv>, date?: Date) {
    this.navigate(`/home/rendez-vous/booking`, {
      state: { medecin, date, step, request }
    });
  }

  openMedecinProfile(id: string) {
    this.openInNewTab(`/home/doctors/${id}`);
  }

}
