import { RdvStep } from './../../../models/enum/rdv.enum';
import { Component, Input } from '@angular/core';
import { IconName } from '@san/tools';
import { Medecin } from './../../../models/entity/medecin';
import { FileDestination } from './../../../models/interfaces/file.interface';
import { NavigationService } from './../../../services/navigation.service';

@Component({
  selector: 'rdv-medecin-resume',
  templateUrl: './medecin-resume.component.html',
  styleUrls: ['./medecin-resume.component.scss']
})
export class MedecinResumeComponent {
  @Input() medecin: Medecin;
  @Input() showRdvButton: boolean = false;
  FileDestination = FileDestination;
  IconName = IconName;

  constructor(private navigationService: NavigationService) { }

  openProfile() {
    this.navigationService.openInNewTab(`/home/doctors/${this.medecin.id}`);
  }

  openBooking() {
    this.navigationService.openBooking(this.medecin, RdvStep.DATE);
  }
}
