import { Component, Input } from '@angular/core';
import { IconName } from '@san/tools';
import { Rdv } from '../../models/entity/rdv';
import { FileDestination } from '../../models/interfaces/file.interface';

@Component({
  selector: 'rdv-preview-bloc',
  templateUrl: './rdv-preview-bloc.component.html',
  styleUrls: ['./rdv-preview-bloc.component.scss']
})
export class RdvPreviewBlocComponent {
  @Input() rdv: Rdv;
  @Input() header: string;
  @Input() icon: IconName = IconName.USER_CLOCK;
  @Input() cancelable: boolean = false;
  IconName = IconName;
  FileDestination = FileDestination;

  get patient() {
    return this.rdv?.patient;
  }
}
