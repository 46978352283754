import { Injectable } from "@angular/core";
import { Subject, switchMap } from 'rxjs';
import { PubliqueService } from "../api/publique.service";
import { SessionKey } from "../models/session.keys";
import { AppconfService } from './app-conf.service';
import { TraductorService } from "./traductor.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  resizingWindow: Subject<Event> = new Subject<Event>();

  static storeSession<T>(key: SessionKey, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static readSession<T>(key: SessionKey): T {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static deleteSession(key: string) {
    sessionStorage.removeItem(key);
  }

  static initialize(appConfService: AppconfService, publiqueService: PubliqueService, traductor: TraductorService): () => Promise<unknown> {
    return (): Promise<unknown> => new Promise((resolve, reject) => {
      try {
        console.info('Lecture de la configuration...');
        appConfService.init().pipe(switchMap(() => publiqueService.appEnv())).subscribe(env => {
          traductor.changeLanguage('fr');
          publiqueService.envData.next(env.data);
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  static timeOut(fn: () => void, time: number = 1) {
    const tm = setTimeout(() => {
      fn();
      clearTimeout(tm);
    }, time);
  }
}
