import { Directive, ElementRef } from '@angular/core';
import { DefaultImage } from '../models/interfaces/file.interface';

@Directive({
  selector: 'img[rdvImageLoader]'
})
export class ImageDirective {

  constructor(private elt: ElementRef) {
    this.elt.nativeElement.addEventListener('error', () => {
      // Pour éviter une boucle Infinie
      this.elt.nativeElement.removeEventListener('error', () => false, false);
      this.elt.nativeElement.src = DefaultImage;
    }, false);
  }

}
