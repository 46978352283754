import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseDialogComponent, IconName } from '@san/tools';
import { filter } from 'rxjs';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'rdv-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent extends BaseDialogComponent implements OnInit {

  IconName: typeof IconName = IconName;
  notifForm: FormGroup;
  user = this.userService.user;

  constructor(protected override injector: Injector, private userService: UserService, private reactiveService: ReactiveService) {
    super(injector);
  }

  ngOnInit(): void {
    this.notifForm = new FormGroup({
      notif_app: new FormControl(this.user?.notif_app),
      notif_sms: new FormControl(this.user?.notif_sms)
    });
  }

  validate() {
    const request = this.notifForm.getRawValue();
    this.reactiveService.call(this.userService.updateNotificationSettings(this.userService.userType, { ...request, id: this.user.id })
      .pipe(filter(res => res.data === true)),
      () => {
        this.user.notif_app = request.notif_app;
        this.user.notif_sms = request.notif_sms;
        this.userService.user = this.user;
        this.close({ content: true });
      }, true);
  }
}
