<san-modal header="core.user.change-password-title" (closeModal)="close()">
  <div content>
    <form [formGroup]="passwordForm">
      <san-input type="password" label="core.user.old-password" placeholder="core.user.mot-de-passe-pl" [maxLength]="20"
        formControlName="mdp" idControl="password-old-password">
      </san-input>
      <san-input type="password" label="core.user.mot-de-passe-new" placeholder="core.user.mot-de-passe-new-pl"
        [maxLength]="20" formControlName="password" idControl="password-new-password">
      </san-input>
      <san-input type="password" label="core.user.confirm-mot-depasse" placeholder="core.user.confirm-mot-depasse-pl"
        [globalErrors]="FormErrors.NOT_SAME" [maxLength]="20" formControlName="confPassword"
        idControl="password-cnf-password">
      </san-input>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-email" label="shared.annuler"
      (onclick)="close()"></san-button-icon>
    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-email" [disabled]="passwordForm.invalid"
      label="shared.valider" (onclick)="validate()"></san-button-icon>
  </div>
</san-modal>