import { Commune } from "../entity/commune";
import { Couverture } from "../entity/couverture";
import { Etablissement } from "../entity/etablissement";
import { Jour } from "../entity/jour";
import { LibelleEntity } from "../entity/libelle.entity";
import { Motif } from "../entity/motif";
import { Moyen } from "../entity/moyen";
import { Specialite } from "../entity/specialite";
import { Ville } from "../entity/ville";

export const DEVISE = 'F CFA';

export interface FileOptions {
  maxFiles: number;
  size: number;
  imageFormat: string;
  docFormat: string;
}

export interface AppConf {
  appName: string;
  phoneDigit: number;
  apiUrl: string;
  file: FileOptions;
}

export interface AppEnvironnement {
  villes: Ville[];
  communes: Commune[];
  motifs: Motif[];
  etablissements: Etablissement[];
  specialites: Specialite[];
  moyens: Moyen[];
  couvertures: Couverture[];
  jours: Jour[];
}

export type SearchTermType = 'motif' | 'etablissement' | 'specialite' | 'ville' | 'commune' | 'all';

export interface SearchTerm extends LibelleEntity {
  type: SearchTermType;
  data: LibelleEntity;
}

export interface GlobalSearchTerm {
  term: SearchTerm;
  city: SearchTerm;
}
