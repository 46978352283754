import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppconfService } from '../../shared/services/app-conf.service';
import { ApiResponse } from './../../shared/models/dto/response.dto';
import { StatusCode } from './../../shared/models/dto/status-code.model';
import { UserService } from './../../shared/services/user.service';
import { InterceptorException } from './../enum/core.enum';

@Injectable({
  providedIn: 'root'
})
export class UrlInterceptor implements HttpInterceptor {

  private isRefreshing: boolean = false;

  constructor(private configService: AppconfService, private userService: UserService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tab: string[] = req.url.split('?');
    if (tab.length > 0) {
      const urlWithoutParameters = tab[0];
      if (!InterceptorException.some(it => urlWithoutParameters.endsWith(it)) && !urlWithoutParameters.startsWith('http')) {
        req = req.clone({
          url: `${this.configService.getAppConf()?.apiUrl}syn-rdv-services/${req.url}`,
          withCredentials: true
        });
        req = this.addToken(req, this.userService.token.getValue()?.token);
      }
    }
    const initialReq = _.cloneDeep(req);
    return next.handle(req).pipe(switchMap(async response => {
      if (response instanceof HttpResponse && !this.creationOrLogin(initialReq.url) &&
        (response.body as ApiResponse<unknown>)?.status === StatusCode.INVALID_TOKEN) {
        return this.refreshToken(initialReq, next);
      } else {
        return response;
      }
    }));
  }

  private creationOrLogin(url: string): boolean {
    return url.endsWith('/login') || url.endsWith('/register');
  }

  private addToken(req: HttpRequest<unknown>, token: string) {
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }
    return req;
  }

  private async refreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const auth = await this.userService.refreshToken();
      this.isRefreshing = false;
      if (auth) {
        return await lastValueFrom(next.handle(this.addToken(request, auth.token)));
      }
    }
    await this.logout();
    return null;
  }

  private async logout() {
    this.isRefreshing = false;
    this.userService.logout();
    this.userService.goHome();
  }
}
