import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppConf } from '../models/interfaces/app-conf.interface';

@Injectable({ providedIn: 'root' })
export class AppconfService {

  private appConf: AppConf;

  constructor(private http: HttpClient) {
  }

  public getAppConf(): AppConf {
    return this.appConf;
  }

  public get imageExtensions() {
    return this.appConf.file.imageFormat.split(',');
  }

  public get docExtensions() {
    return this.appConf.file.docFormat.split(',');
  }

  public get extensions() {
    return [...this.docExtensions, ...this.imageExtensions];
  }

  public init(): Observable<AppConf> {
    return this.http.get<AppConf>('./app.conf.json').pipe(take(1), tap((appConf: AppConf) => this.appConf = _.cloneDeep(appConf)));
  }
}
