import { UserService } from './../../../services/user.service';
import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, IconName, ListModel } from '@san/tools';
import * as _ from 'lodash';
import { PubliqueService } from './../../../api/publique.service';
import { Couverture } from './../../../models/entity/couverture';
import { Diplome } from './../../../models/entity/diplome';
import { Medecin } from './../../../models/entity/medecin';
import { Moyen } from './../../../models/entity/moyen';
import { Prestation } from './../../../models/entity/prestation';
import { Specialite } from './../../../models/entity/specialite';
import { MedecinLazyRelations } from './../../../models/enum/relations.enum';
import { ReactiveService } from './../../../services/reactive.service';
import { ObjectUtility } from './../../../utils/object.utils';

@Component({
  selector: 'rdv-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.scss']
})
export class DoctorProfileComponent extends BaseComponent implements AfterViewInit {

  IconName = IconName;
  medecin: Medecin;
  prestations: ListModel<string>[] = [];
  specialites: ListModel<number>[] = [];
  moyens: ListModel<number>[] = [];
  couvertures: ListModel<number>[] = [];
  diplomes: Diplome[] = [];
  MedecinLazyRelations = MedecinLazyRelations;
  isMedecin = this.userService.medecinIsConnected;

  constructor(private publiqueService: PubliqueService, private userService: UserService,
    private route: ActivatedRoute, public reactiveService: ReactiveService) {
    super();
  }

  ngAfterViewInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id?.length) {
      this.reactiveService.call(this.publiqueService.findMedecin(id, Object.values(MedecinLazyRelations)), res => {
        this.medecin = res.data;
        this.prestations = this.getListItems(this.medecin.prestations?.filter(pres => pres.actif));
        this.diplomes = this.medecin.diplomes;
        this.specialites = this.getListItems(this.medecin.specialites);
        this.moyens = this.getListItems(this.medecin.moyens);
        this.couvertures = this.getListItems(this.medecin.couvertures);
      }, false);
    }

  }

  getListItems(items: (Specialite | Prestation | Moyen | Couverture)[]): ListModel<any>[] {
    return items?.map(it => ({
      label: _.has(it, 'motif') ? `${_.get(it, 'motif.libelle')} - ${ObjectUtility.formatPrice(_.get(it, 'tarif'))}` : _.get(it, 'libelle'),
      value: it.id,
      data: it
    } as ListModel<unknown>));
  }
}
