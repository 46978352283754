import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SanBlocModule, SanFormsModule, SanListViewModule, SanMenuModule, SanModalModule, SanSharedModule } from '@san/tools';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { AvailablePeriodsComponent } from './components/available-periods/available-periods.component';
import { CheckListModalComponent } from './components/check-list-modal/check-list-modal.component';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { FilterEventComponent } from './components/filters/rdv-filter/rdv-filter.component';
import { UserFilterComponent } from './components/filters/user-filter/user-filter.component';
import { HospitalComponent } from './components/hospital/hospital.component';
import { MedecinDiplomeDialogComponent } from './components/medecin/medecin-diplome-dialog/medecin-diplome-dialog.component';
import { MedecinDiplomeComponent } from './components/medecin/medecin-diplome/medecin-diplome.component';
import { MedecinInfoComponent } from './components/medecin/medecin-info/medecin-info.component';
import { DoctorProfileComponent } from './components/medecin/profile/doctor-profile.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { RdvDetailComponent } from './components/rdv-detail/rdv-detail.component';
import { RdvDialogComponent } from './components/rdv-dialog/rdv-dialog.component';
import { ReservationBlocComponent } from './components/reservation-bloc/reservation-bloc.component';
import { FileSizePipe, ImageFilePipe, PreviewFilePipe } from './pipes/file.pipe';
import { DisplayDatePipe, DisplayHourPipe, DisplayListPipe, DisplayPricePipe, DisplayValuePipe, LongDatePipe } from './pipes/object.pipe';
import { RdvStatusPipe } from './pipes/rdv.pipe';
import { FullNamePipe, GenrePipe, MedecinIsConnectedPipe, PatientIsConnectedPipe, UserIsConnectedPipe } from './pipes/user.pipe';
import { MedecinResumeComponent } from './components/medecin/medecin-resume/medecin-resume.component';
import { PatientInfoComponent } from './components/patient/patient-info/patient-info.component';
import { PhotoComponent } from './components/photo/photo.component';
import { SyntheseEventsBlocComponent } from './components/synthese-events-bloc/synthese-events-bloc.component';
import { RdvPreviewBlocComponent } from './components/rdv-preview-bloc/rdv-preview-bloc.component';
import { ImageDirective } from './directives/image.directive';
import { PatientDetailComponent } from './components/patient/patient-detail/patient-detail.component';

const sanModules = [
  SanSharedModule,
  SanFormsModule,
  SanModalModule,
  SanBlocModule,
  SanMenuModule,
  SanListViewModule
];

const exportables = [
  MenuBarComponent,
  AppointmentComponent,
  MedecinInfoComponent,
  MedecinResumeComponent,
  MedecinDiplomeComponent,
  MedecinDiplomeDialogComponent,
  CheckListModalComponent,
  ReservationBlocComponent,
  AvailablePeriodsComponent,
  DoctorProfileComponent,
  HospitalComponent,
  RdvDialogComponent,
  RdvDetailComponent,
  FilterEventComponent,
  CommentDialogComponent,
  MedecinIsConnectedPipe,
  PatientIsConnectedPipe,
  UserIsConnectedPipe,
  ImageFilePipe,
  PreviewFilePipe,
  FileSizePipe,
  DisplayValuePipe,
  DisplayDatePipe,
  DisplayPricePipe,
  DisplayListPipe,
  GenrePipe,
  DisplayHourPipe,
  LongDatePipe,
  FullNamePipe,
  UserFilterComponent,
  PatientInfoComponent,
  PhotoComponent,
  RdvStatusPipe,
  SyntheseEventsBlocComponent,
  RdvPreviewBlocComponent,
  ImageDirective,
  PatientDetailComponent
];

@NgModule({
  declarations: [
    ...exportables
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    PortalModule,
    DialogModule,
    OverlayModule,
    CdkMenuModule,
    TranslateModule.forChild(),
    ...sanModules
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    PortalModule,
    DialogModule,
    RouterModule,
    OverlayModule,
    CdkMenuModule,
    ...sanModules,
    ...exportables
  ],
  providers: []
})
export class SharedModule {
}
