import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SearchParam } from '@san/tools';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { LibelleEntity } from '../models/entity/libelle.entity';
import { Medecin } from '../models/entity/medecin';
import { AppEnvironnement, GlobalSearchTerm, SearchTerm, SearchTermType } from '../models/interfaces/app-conf.interface';
import { AppointmentPeriod } from '../models/interfaces/rdv.interface';
import { EmailRequest, LoginRequest, LoginToken, PasswordRequest, VerificationData } from "../models/interfaces/user.interfaces";
import { MedecinRequest } from "../models/request/medecin.request";
import { PatientRequest } from '../models/request/patient.request';
import { PeriodRequest } from '../models/request/rdv.request';
import { SimpleEntity } from './../models/enum/simple.enum';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class PubliqueService extends ApiBaseService {

  static MEDECIN_PATH: string = 'medecin';
  static PATIENT_PATH: string = 'patient';
  static COMMON_PATH: string = 'common';
  static RDV_PATH: string = 'rdv';
  envData: BehaviorSubject<AppEnvironnement> = new BehaviorSubject<AppEnvironnement>(null);

  constructor(override http: HttpClient) {
    super(http, 'publique');
  }

  /**
   * Create a medecin
   * @param request
   * @returns
   */
  registerMedecin(request: MedecinRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/register`, request);
  }

  /**
   * Login medecin
   * @param request
   * @returns
   */
  connectMedecin(request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return this.http.post<ApiResponse<LoginToken>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/login`, request);
  }

  /**
   * Change medecin password
   * @param request
   * @returns
   */
  changeMedecinPassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/change-password`, request);
  }

  verifyMedecinEmail(request: VerificationData): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/verify-email`, request);
  }

  /**
   * Recover medecin password
   * @param request
   * @returns
   */
  recoverMedecinPassword(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/forget-password`, request);
  }

  /**
   * Create a patient
   * @param request
   * @returns
   */
  registerPatient(request: PatientRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/register`, request);
  }

  /**
   * Login patient
   * @param request
   * @returns
   */
  connectPatient(request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return this.http.post<ApiResponse<LoginToken>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/login`, request);
  }

  /**
   * Change patient password
   * @param request
   * @returns
   */
  changePatientPassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/change-password`, request);
  }

  verifyPatientEmail(request: VerificationData): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/verify-email`, request);
  }

  /**
   * Recover patient password
   * @param request
   * @returns
   */
  recoverPatientPassword(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/forget-password`, request);
  }


  /**
   * get a common entity
   * @param repo
   * @param id
   * @param relations
   * @returns
   */
  commonFind<T>(repo: SimpleEntity, id: string, relations?: string[]): Observable<ApiResponse<T>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/find${query}`, { id, relations });
  }

  /**
   * Paginate entities
   * @param repo
   * @param param
   * @returns
   */
  commonPaginate<T>(repo: SimpleEntity, param?: SearchParam): Observable<ApiResponse<T[]>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T[]>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/list/paginate${query}`, param);
  }

  /**
   * Search entities
   * @param repo
   * @param param
   * @returns
   */
  commonSearch<T>(repo: SimpleEntity, param?: SearchParam): Observable<ApiResponse<T[]>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T[]>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/list/full${query}`, param);
  }

  /**
   * Load app env data
   * @returns
   */
  appEnv(): Observable<ApiResponse<AppEnvironnement>> {
    return this.http.get<ApiResponse<AppEnvironnement>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/app/environnement`);
  }

  searchMedecins(searchTerm: GlobalSearchTerm) {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/search/medecins`, searchTerm);
  }

  findMedecin(id: string, relations?: string[]) {
    return this.http.post<ApiResponse<Medecin>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/medecin/find`, { id, relations });
  }

  availableRdvPeriods(param: PeriodRequest) {
    return this.http.post<ApiResponse<AppointmentPeriod[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/get/available-rdv-periods`, param);
  }

  availableMedecins(param: SearchParam) {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/get/available-medecins`, param);
  }

  public static createSearchOptions(data: LibelleEntity[], type: SearchTermType): SearchTerm[] {
    return (data ?? []).map((d: LibelleEntity) => ({ ...d, type, data: d } as SearchTerm));
  }

}
