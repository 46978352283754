import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDialogComponent, IconName } from '@san/tools';
import { DiplomeService } from './../../../api/diplome.service';
import { MedecinService } from './../../../api/medecin.service';
import { Diplome } from './../../../models/entity/diplome';
import { Medecin } from './../../../models/entity/medecin';
import { DiplomeRequest } from './../../../models/request/medecin.request';
import { ReactiveService } from './../../../services/reactive.service';

@Component({
  selector: 'rdv-medecin-diplome-dialog',
  templateUrl: './medecin-diplome-dialog.component.html',
  styleUrls: ['./medecin-diplome-dialog.component.scss']
})
export class MedecinDiplomeDialogComponent extends BaseDialogComponent implements OnInit {
  diplome: Diplome;
  medecin: Medecin;
  diplomeForm: FormGroup;
  IconName = IconName;

  constructor(protected override injector: Injector, private medecinService: MedecinService,
    private reactiveService: ReactiveService, private diplomeService: DiplomeService) {
    super(injector);
    this.medecin = this.medecinService.connectedMedecin.getValue();
  }

  ngOnInit(): void {
    this.diplome = this.data?.content;
    this.diplomeForm = new FormGroup({
      libelle: new FormControl(this.diplome?.libelle, Validators.required),
      etablissement: new FormControl(this.diplome?.etablissement, Validators.required),
      annee: new FormControl(this.diplome?.annee, Validators.required)
    });
  }

  save() {
    const data = this.diplomeForm.getRawValue();
    const request: DiplomeRequest = { ...data, medecin: this.medecin.id, id: this.diplome?.id };
    this.reactiveService.call(this.diplomeService.save(request), (res) => {
      this.diplome = { ...this.diplome, ...data, id: res.data?.id };
      this.close({ content: this.diplome });
    }, true);
  }
}
