import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Column, ColumnType, SearchCriteria, SearchParam, SortDirection, SortOrder } from '@san/tools';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { RdvType } from 'src/app/shared/models/enum/rdv.enum';
import { ApiResponse } from '../models/dto/response.dto';
import { Horaire } from '../models/entity/horaire';
import { Medecin } from "../models/entity/medecin";
import { Patient } from '../models/entity/patient';
import { Prestation, PrestationRequest } from '../models/entity/prestation';
import { Rdv } from '../models/entity/rdv';
import { Etat } from '../models/enum/state.enum';
import { FileResponse } from '../models/interfaces/file.interface';
import { SearchUserParam } from '../models/interfaces/search.interface';
import { EmailRequest, NotificationRequest, PasswordRequest } from "../models/interfaces/user.interfaces";
import { HoraireRequest, MedecinEnvironment, MedecinRdvSettingsRequest, MedecinRequest, MedecinSimplesRequest } from "../models/request/medecin.request";
import { ObjectUtility } from '../utils/object.utils';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class MedecinService extends ApiBaseService {
  connectedMedecin: BehaviorSubject<Medecin> = new BehaviorSubject<Medecin>(null);
  public readonly userOrder: SortOrder = { field: 'nom', direction: SortDirection.ASC };
  public userColumns: Column[] = [
    { field: 'matricule', sortable: true },
    { field: 'nom', sortable: true, defaultSort: true },
    { field: 'prenoms', sortable: true },
    { field: 'email', sortable: true },
    {
      field: 'telephone',
      sortable: true,
      displayFn: (medecin: Medecin) => ObjectUtility.formatTel(medecin.telephone, medecin.portable)
    },
    { field: 'actif', type: ColumnType.BOOLEEN }
  ];

  constructor(override http: HttpClient) {
    super(http, 'medecins');
  }

  /**
   * Modifie les infos d'un medecin
   * @param medecin
   * @returns
   */
  edit(medecin: MedecinRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(this.baseUrl, medecin);
  }

  /**
   * Renvoie un medecin
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Medecin>> {
    return this.http.post<ApiResponse<Medecin>>(`${this.baseUrl}/find`, { id, relations });
  }

  /**
   * Pagine des medecin
   * @param param
   * @returns
   */
  paginate(param?: SearchParam): Observable<ApiResponse<Medecin[]>> {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/list/paginate`, param);
  }

  /**
   * Recherche des medecin
   * @param param
   * @returns
   */
  search(param?: SearchParam): Observable<ApiResponse<Medecin[]>> {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/list/full`, param);
  }

  /**
   * Charge l'env du medecin connecte
   * @returns
   */
  environnement(): Observable<ApiResponse<MedecinEnvironment>> {
    return this.http.get<ApiResponse<MedecinEnvironment>>(`${this.baseUrl}/get/environnement`)
      .pipe(take(1), filter(env => !!env?.data?.user?.id), tap((rep: ApiResponse<MedecinEnvironment>) => this.connectedMedecin.next(rep.data.user)));
  }

  /**
   * Modifie le mot de passe d'un medecin
   * @param request
   * @returns
   */
  updatePassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-password`, request);
  }

  updateNotificationSettings(request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-notification-settings`, request);
  }

  /**
   * Modifie le mail d'un medecin
   * @param request
   * @returns
   */
  updateEmail(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-email`, request);
  }

  updateRdvSettings(request: MedecinRdvSettingsRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-rdv-settings`, request);
  }

  saveHoraire(request: HoraireRequest): Observable<ApiResponse<Horaire>> {
    return this.http.post<ApiResponse<Horaire>>(`${this.baseUrl}/save-horaire`, request);
  }

  /**
   * Change la photo de profil du medecin
   * @param data
   * @returns
   */
  updatePhoto(data: FormData): Observable<ApiResponse<FileResponse>> {
    return this.http.put<ApiResponse<FileResponse>>(`${this.baseUrl}/update-photo`, data);
  }

  deletePhoto(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-photo/${id}`);
  }

  updateSimples(request: MedecinSimplesRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-simples`, request);
  }

  createPrestation(request: PrestationRequest): Observable<ApiResponse<Prestation>> {
    return this.http.post<ApiResponse<Prestation>>(`${this.baseUrl}/create-prestation`, request);
  }

  editPrestation(request: PrestationRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/edit-prestation`, request);
  }

  togglePrestation(id: string, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/toggle-prestation/${id}/${state}`, null);
  }

  deletePrestation(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-prestation/${id}`);
  }

  toggleHoraire(id: number, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/toggle-horaire/${id}/${state}`, null);
  }

  getHoraires(): Observable<ApiResponse<Horaire[]>> {
    return this.http.get<ApiResponse<Horaire[]>>(`${this.baseUrl}/get/horaires`);
  }

  getCalendarEvents(param: SearchParam): Observable<ApiResponse<Rdv[]>> {
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/get/calendar-events`, param);
  }

  getEvents(type: RdvType, param: SearchParam, forExport: boolean = false): Observable<ApiResponse<Rdv[]>> {
    const query = forExport ? `?export=true` : '';
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/${type}/list/events${query}`, param);
  }

  getPrestations(param?: SearchParam): Observable<ApiResponse<Prestation[]>> {
    return this.http.post<ApiResponse<Prestation[]>>(`${this.baseUrl}/get/prestations`, param);
  }

  getPatients(param?: SearchCriteria<SearchUserParam>): Observable<ApiResponse<Patient[]>> {
    return this.http.post<ApiResponse<Patient[]>>(`${this.baseUrl}/get/patients`, param);
  }

  /**
   * Verifie si ID correspond a celui du medecin connecte
   * @param id
   * @returns
   */
  isConnectedMedecin(id: string): boolean {
    return id === this.connectedMedecin.getValue()?.id;
  }

}
