<san-bloc [header]="header" [icon]="icon">
  <div content>
    <ng-container *ngIf="events?.length else empty;">
      <rdv-appointment *ngFor="let rdv of events;" [rdv]="rdv" (rdvChange)="rdvChange.next()"></rdv-appointment>
    </ng-container>
  </div>
</san-bloc>

<ng-template #empty>
  <div class="empty" *ngIf="!events?.length" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <san-icon [clickable]="false" [name]="IconName.WARNING" size="2xl" color="accent"></san-icon>
    <div>{{'rdv.aucunElement' | translate}}</div>
  </div>
</ng-template>