import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, Observable, catchError, combineLatest, lastValueFrom, map, of, take } from 'rxjs';
import { MedecinService } from "../api/medecin.service";
import { PatientService } from "../api/patient.service";
import { PubliqueService } from "../api/publique.service";
import { ApiResponse } from '../models/dto/response.dto';
import { StatusCode } from "../models/dto/status-code.model";
import { Medecin } from "../models/entity/medecin";
import { Patient } from "../models/entity/patient";
import { AccountType } from "../models/enum/user-shared.enum";
import { EmailRequest, LoginRequest, LoginToken, NotificationRequest, PasswordRequest, TokenInfo, VerificationData } from "../models/interfaces/user.interfaces";
import { MedecinEnvironment, MedecinRequest } from "../models/request/medecin.request";
import { PatientEnvironment, PatientRequest } from "../models/request/patient.request";
import { SessionKey } from "../models/session.keys";
import { ObjectUtility } from "../utils/object.utils";
import { UserType } from './../models/enum/user-type.enum';
import { RefreshTokenRequest } from './../models/interfaces/user.interfaces';
import { ApplicationService } from "./application.service";
import { LoaderService } from "./loader.service";
import { NavigationService } from "./navigation.service";
import { NotificationService } from "./notifaction.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  token: BehaviorSubject<LoginToken> = new BehaviorSubject<LoginToken>(null);

  constructor(private http: HttpClient,
    private navigationService: NavigationService,
    public notificationService: NotificationService,
    private publiqueService: PubliqueService,
    private loaderService: LoaderService,
    private medecinService: MedecinService,
    private patientService: PatientService) {
  }

  /**
   * Create an user
   * @param type
   * @param request
   * @returns
   */
  register(type: UserType, request: MedecinRequest | PatientRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.publiqueService.registerMedecin(request as MedecinRequest)
      : this.publiqueService.registerPatient(request as PatientRequest);
  }

  /**
   * Login user
   * @param type
   * @param request
   * @returns
   */
  connect(type: UserType, request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return type === UserType.MEDECIN ? this.publiqueService.connectMedecin(request) : this.publiqueService.connectPatient(request);
  }

  /**
   * User environment
   * @param type
   * @returns
   */
  environnement(type: UserType): Observable<ApiResponse<MedecinEnvironment | PatientEnvironment>> {
    return type === UserType.MEDECIN ? this.medecinService.environnement() : this.patientService.environnement();
  }

  /**
   * Update user email when connected
   * @param type
   * @param request
   * @returns
   */
  updateEmail(type: UserType, request: EmailRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.medecinService.updateEmail(request) : this.patientService.updateEmail(request);
  }

  /**
   * Update user password when connected
   * @param type
   * @param request
   * @returns
   */
  updatePassword(type: UserType, request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.medecinService.updatePassword(request) : this.patientService.updatePassword(request);
  }

  updateNotificationSettings(type: UserType, request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.medecinService.updateNotificationSettings(request) : this.patientService.updateNotificationSettings(request);
  }

  /**
   * Change password
   * @param type
   * @param request
   * @returns
   */
  changePassword(type: UserType, request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.publiqueService.changeMedecinPassword(request) : this.publiqueService.changePatientPassword(request);
  }

  verifyEmail(type: UserType, request: VerificationData): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.publiqueService.verifyMedecinEmail(request) : this.publiqueService.verifyPatientEmail(request);
  }

  /**
   * Recover password
   * @param type
   * @param request
   * @returns
   */
  recoverPassword(type: UserType, request: EmailRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.MEDECIN ? this.publiqueService.recoverMedecinPassword(request) : this.publiqueService.recoverPatientPassword(request);
  }

  get connectedUser(): UserType {
    const medecin = this.medecinService.connectedMedecin.getValue();
    const patient = this.patientService.connectedPatient.getValue();
    if (medecin) {
      return UserType.MEDECIN;
    } else if (patient) {
      return UserType.PATIENT;
    }
    return null;
  }

  async refreshToken(displayError: boolean = true): Promise<LoginToken> {
    const token = ApplicationService.readSession<LoginToken>(SessionKey.TOKEN);
    if (token?.token && token?.refreshToken) {
      const data: TokenInfo = jwtDecode(token.token);
      const subUrl: string = data.type === UserType.MEDECIN ? PubliqueService.MEDECIN_PATH : PubliqueService.PATIENT_PATH;
      const request: RefreshTokenRequest = { state: token.token, uuid: data.uuid, refreshToken: token.refreshToken };
      const res = await lastValueFrom(this.http.post<ApiResponse<LoginToken>>(`publique/${subUrl}/refresh-token`, request)).catch(() => null);

      if (res?.status === StatusCode.OK && res.data?.token) {
        await this.initEnv(data.type, res.data, false);
        return res.data;
      } else {
        if (displayError) {
          this.notificationService.error(`core.error.${StatusCode.INVALID_TOKEN}`);
        }
        this.logout();
      }
    }
    return null;
  }

  get userIsConnected(): boolean {
    return !!this.connectedUser;
  }

  get connected$(): Observable<boolean> {
    return combineLatest([
      this.medecinService.connectedMedecin.asObservable(),
      this.patientService.connectedPatient.asObservable(),
    ]).pipe(map(([med, pat]) => !!med?.id || !!pat?.id));
  }

  get medecinIsConnected(): boolean {
    return this.connectedUser === UserType.MEDECIN;
  }

  get patientIsConnected(): boolean {
    return this.connectedUser === UserType.PATIENT;
  }

  get user() {
    return this.medecinIsConnected ? this.medecinService.connectedMedecin.getValue() : this.patientService.connectedPatient.getValue();
  }

  set user(us: Medecin | Patient) {
    if (this.medecinIsConnected) {
      this.medecinService.connectedMedecin.next(us as Medecin);
      this.patientService.connectedPatient.next(null);
    } else {
      this.patientService.connectedPatient.next(us as Patient);
      this.medecinService.connectedMedecin.next(null);
    }
  }

  get dashboardLink(): string {
    return this.medecinIsConnected ? '/medecin' : '/patient';
  }

  get accountType() {
    return this.connectedUser === UserType.MEDECIN ? AccountType.MEDECIN : AccountType.PATIENT;
  }

  get userType() {
    return this.medecinIsConnected ? UserType.MEDECIN : UserType.PATIENT;
  }

  async initEnv(type: UserType, token: LoginToken, redirect: boolean = true) {
    this.token.next(token);
    const res = await lastValueFrom(this.environnement(type).pipe(take(1), catchError(() => of(null))));
    if (res?.status === StatusCode.OK && res?.data.user?.id) {
      ApplicationService.storeSession(SessionKey.TOKEN, token);
      this.loaderService.removeLoaders();
      if (redirect) {
        this.navigationService.navigate(this.dashboardLink);
      }
    }
  }

  static hasVerificationData(data: VerificationData): boolean {
    return !!data?.token && !!data?.id && !!data?.email && !ObjectUtility.isNullOrUndefined(data?.type);
  }

  static getFullName(user: Medecin | Patient): string {
    let name = user.nom;
    if (user.prenoms) {
      name = `${user.prenoms} ${name}`;
    }
    return name;
  }

  redirectConnectedUser() {
    if (this.connectedUser) {
      this.navigationService.navigate(this.dashboardLink);
    }
  }

  goHome() {
    this.navigationService.goHome();
  }

  logout() {
    this.token.next(null);
    this.medecinService.connectedMedecin.next(null);
    this.patientService.connectedPatient.next(null);
    ApplicationService.deleteSession(SessionKey.TOKEN);
  }

}
