import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, IconName } from '@san/tools';
import { cloneDeep } from 'lodash';
import { debounceTime, take, takeUntil, tap } from 'rxjs';
import { PubliqueService } from '../../api/publique.service';
import { AppointmentPeriod } from '../../models/interfaces/rdv.interface';
import { PeriodRequest } from '../../models/request/rdv.request';
import { ApplicationService } from '../../services/application.service';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'rdv-available-periods',
  templateUrl: './available-periods.component.html',
  styleUrls: ['./available-periods.component.scss']
})
export class AvailablePeriodsComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() medecinId: string;
  param: PeriodRequest;
  periods: AppointmentPeriod[] = [];
  IconName = IconName;
  start: Date = DateService.trunk();
  width: number = window.innerWidth;
  nbDay: number = 7;
  loading: boolean = true;
  more: boolean = false;
  @Output() clickOnTime: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private publiqueService: PubliqueService, private appService: ApplicationService, private elt: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.appService.resizingWindow.pipe(debounceTime(500), takeUntil(this.destroy)).subscribe(() => this.refresh());
  }

  ngAfterViewInit(): void {
    this.refresh(true);
  }

  refresh(init: boolean = false) {
    this.width = this.elt.nativeElement.offsetWidth;
    let nbDay = 7;
    if (this.width < 273) {
      nbDay = 1;
    } else if (this.width < 375) {
      nbDay = 2;
    } else if (this.width < 550) {
      nbDay = 3;
    } else if (this.width < 750) {
      nbDay = 5;
    }
    if (init || nbDay !== this.nbDay) {
      this.nbDay = nbDay;
      this.param = {
        medecin: this.medecinId,
        start: this.start,
        end: DateService.dateAfterDays(this.nbDay - 1, DateService.lastDayTime(this.start))
      };
      this.availableRdvPeriods();
    }
  }

  get canGoBack() {
    return this.start > DateService.trunk();
  }

  get emptyPeriods() {
    return this.periods?.every(p => !p.periods?.length);
  }

  get showMoreBtn() {
    return this.periods?.some(p => p.periods?.length > 5);
  }

  prev() {
    this.param.end = DateService.dateBeforeDays(1, DateService.lastDayTime(cloneDeep(this.start)));
    this.start = DateService.dateBeforeDays(this.nbDay, this.start);
    this.param.start = this.start;
    this.availableRdvPeriods();
  }

  next() {
    this.start = DateService.dateAfterDays(this.nbDay, this.start);
    this.param.start = this.start;
    this.param.end = DateService.dateAfterDays(this.nbDay - 1, DateService.lastDayTime(this.start));
    this.availableRdvPeriods();
  }

  availableRdvPeriods() {
    this.loading = true;
    this.publiqueService.availableRdvPeriods(this.param)
      .pipe(debounceTime(500), take(1), tap(() => this.loading = false))
      .subscribe(res => this.periods = res.data);
  }

  get moreBtnTitle(): string {
    return this.more ? 'rdv.moins-horaires' : 'rdv.plus-horaires';
  }

  toggleMoreTimes() {
    this.more = !this.more;
  }
}
