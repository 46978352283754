import { Component, Input } from '@angular/core';
import { IconName } from '@san/tools';
import { Medecin } from '../../models/entity/medecin';
import { RdvStep } from '../../models/enum/rdv.enum';
import { FileDestination } from '../../models/interfaces/file.interface';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'rdv-reservation-bloc',
  templateUrl: './reservation-bloc.component.html',
  styleUrls: ['./reservation-bloc.component.scss']
})
export class ReservationBlocComponent {
  @Input() medecin: Medecin;
  IconName = IconName;
  FileDestination = FileDestination;

  constructor(private navigationService: NavigationService) {
  }

  openProfile() {
    this.navigationService.openMedecinProfile(this.medecin.id);
  }

  openBooking(date: Date) {
    this.navigationService.openBooking(this.medecin, RdvStep.RAISON, null, date);
  }

}
