export enum PatientRelations {
  VILLE = 'ville',
  COMMUNE = 'commune'
}

export enum MedecinLazyRelations {
  VILLE = 'ville',
  ETABLISSEMENT = 'etablissement',
  COMMUNE = 'commune',
  SPECIALITES = 'specialites',
  MOYENS = 'moyens',
  COUVERTURES = 'couvertures',
  DIPLOMES = 'diplomes',
  PRESTATIONS = 'prestations.motif',
  ETABLISSEMENT_COMMUNE = 'etablissement.commune',
  ETABLISSEMENT_VILLE = 'etablissement.ville',
}

export enum MedecinRelations {
  RDV = 'rdv',
  HORAIRES = 'horaires'
}

export enum VilleRelations {
  COMMUNES = 'communes',
  ETABLISSEMENTS = 'etablissements',
  PATIENTS = 'patients',
  MEDECINS = 'medecins'
}

export enum CommuneRelations {
  VILLE = 'ville',
  ETABLISSEMENTS = 'etablissements',
  PATIENTS = 'patients',
  MEDECINS = 'medecins'
}

export const RdvMedecinRelation = ['medecin', 'prestation.motif'];
export const RdvPatientRelation = ['patient', 'prestation.motif'];
