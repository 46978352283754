import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, FilterUtils, IconName, OptionModel, SearchCondition } from '@san/tools';
import { Observable, debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs';
import { MedecinService } from '../../../api/medecin.service';
import { RdvType, StatusRdv } from '../../../models/enum/rdv.enum';
import { FilterParam } from '../../../models/interfaces/filter-options.interface';
import { UserService } from './../../../services/user.service';

@Component({
  selector: 'rdv-filter-events',
  templateUrl: './rdv-filter.component.html',
  styleUrls: ['./rdv-filter.component.scss']
})
export class FilterEventComponent extends BaseComponent implements OnInit {

  isMedecin = this.userService.medecinIsConnected;
  IconName = IconName;
  searchForm: FormGroup;
  types: OptionModel<RdvType>[] = Object.values(RdvType).map(type => ({ value: type, label: `rdv.${type}-type` }));
  prestations$: Observable<OptionModel<string>[]>;
  status: OptionModel<StatusRdv>[] = Object.values(StatusRdv).map(status => ({ value: status, label: `rdv.etat.${status}` }));

  @Output() searching: EventEmitter<FilterParam> = new EventEmitter<FilterParam>();

  constructor(private medecinService: MedecinService, private userService: UserService, private route: ActivatedRoute) {
    super();
    this.prestations$ = this.medecinService.getPrestations().pipe(take(1), filter(() => this.isMedecin),
      map(res => res.data?.map(p => ({ value: p.id, label: p.motif.libelle }))));
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      type: new FormControl(this.types[0].value),
      debut: new FormControl(),
      fin: new FormControl(),
      prestations: new FormControl(),
      status: new FormControl(),
      term: new FormControl(this.route.snapshot.paramMap.get('numero') ?? '')
    });

    this.searchForm.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy)).subscribe(() => this.search());

    this.search();
  }

  get type() {
    return this.searchForm.get('type').value;
  }

  get isRdv() {
    return this.type === RdvType.RDV;
  }

  get termPlaceholder() {
    return this.isRdv ? 'rdv.filter.term-rdv-pl' : 'rdv.filter.term-event-pl';
  }

  buildParam() {
    const param = this.searchForm.getRawValue();
    const option = FilterUtils.generateFilter(SearchCondition.AND);
    const utils = new FilterUtils();
    if (param.debut) {
      option.filters.push(utils.dateFilter('debut').superiorEqual(param.debut).get());
    }
    if (param.fin) {
      option.filters.push(utils.dateFilter('fin').inferiorEqual(param.fin).get());
    }

    if (param.status?.length) {
      option.filters.push(utils.stringFilter('status').includes(param.status).get());
    }

    if (this.isMedecin) {
      if (this.isRdv && param.prestations?.length) {
        option.filters.push(utils.stringFilter('prestation.id').includes(param.prestations).get());
      }

      const term = param.term?.toString().trim();
      if (term?.length > 0) {
        const subFilter = FilterUtils.generateSubFilter(SearchCondition.OR);
        subFilter.subFilters.push(utils.stringFilter('motif').contains(term).get());
        subFilter.subFilters.push(utils.stringFilter('lieu').contains(term).get());
        if (this.isRdv) {
          subFilter.subFilters.push(utils.stringFilter('patient.numero').contains(term).get());
          subFilter.subFilters.push(utils.stringFilter('patient.nom').contains(term).get());
          subFilter.subFilters.push(utils.stringFilter('patient.prenoms').contains(term).get());
        }
        option.filters.push(subFilter);
      }
    }
    return option;
  }

  search() {
    this.searching.next({ type: this.type, filter: this.buildParam() });
  }

}
