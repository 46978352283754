import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { Diplome } from '../models/entity/diplome';
import { FileResponse } from '../models/interfaces/file.interface';
import { DiplomeRequest } from "../models/request/medecin.request";
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class DiplomeService extends ApiBaseService {

  static addDiplome$: Subject<void> = new Subject<void>();

  constructor(override http: HttpClient) {
    super(http, 'diplomes');
  }

  /**
   * Enregistre ou modifie un diplome
   * @param request
   * @returns
   */
  save(request: DiplomeRequest): Observable<ApiResponse<Diplome>> {
    return this.http.post<ApiResponse<Diplome>>(this.baseUrl, request);
  }

  /**
   * Renvoie un diplome
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Diplome>> {
    return this.http.post<ApiResponse<Diplome>>(`${this.baseUrl}/find`, { id, relations });
  }

  /**
   * Liste les diplomes d'un medecin
   * @returns
   */
  getByMedecin(): Observable<ApiResponse<Diplome[]>> {
    return this.http.get<ApiResponse<Diplome[]>>(`${this.baseUrl}/list`);
  }

  /**
   * Supprime le fichier d'un diplome
   * @param id
   * @returns
   */
  deleteFile(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-file/${id}`);
  }

  /**
   * Met a jour le fichier d'un diplome
   * @param data
   * @returns
   */
  editFile(data: FormData): Observable<ApiResponse<FileResponse>> {
    return this.http.put<ApiResponse<FileResponse>>(`${this.baseUrl}/update-file`, data);
  }

  /**
   * Supprime un diplome
   * @param id
   * @returns
   */
  delete(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete/${id}`);
  }

}
