import { Pipe, PipeTransform } from "@angular/core";
import { Medecin } from "../models/entity/medecin";
import { Patient } from "../models/entity/patient";
import { UserType } from "../models/enum/user-type.enum";
import { TraductorService } from "../services/traductor.service";
import { UserService } from "../services/user.service";

@Pipe({ name: 'userIsConnected' })
export class UserIsConnectedPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(): boolean {
    return !!this.userService.connectedUser;
  }
}


@Pipe({ name: 'medecinIsConnected' })
export class MedecinIsConnectedPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(): boolean {
    return this.userService.connectedUser === UserType.MEDECIN;
  }
}

@Pipe({ name: 'patientIsConnected' })
export class PatientIsConnectedPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(): boolean {
    return this.userService.connectedUser === UserType.PATIENT;
  }
}

@Pipe({ name: 'genre' })
export class GenrePipe implements PipeTransform {

  constructor(private traductor: TraductorService) {
  }

  transform(sexe: string): string {
    return sexe ? this.traductor.translate(`core.user.${sexe}`) : '-';
  }

}

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {

  transform(user: Patient | Medecin): string {
    return UserService.getFullName(user);
  }
}
