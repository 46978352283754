<san-modal [header]="title" (closeModal)="close()">
  <div content>
    <form [formGroup]="form">

      <p *ngIf="param?.message" [innerHTML]="param?.message"></p>

      <san-input *ngIf="param.type !== CommentType.HIDE" type="textarea" [cols]="10" [rows]="5" [label]="label"
        [maxLength]="maxlength" formControlName="comment" idControl="rdv-comment-dialog-field">
      </san-input>

    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-rdv" label="shared.quitter"
      (onclick)="close()"></san-button-icon>

    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-rdv" [disabled]="form.invalid"
      [label]="validationLabel" (onclick)="validate()"></san-button-icon>
  </div>
</san-modal>