import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDialogComponent, FilterUtils, IconName, OptionModel, SearchCondition } from '@san/tools';
import { Observable, debounceTime, map, take, takeUntil } from 'rxjs';
import { MedecinService } from '../../api/medecin.service';
import { RdvService } from '../../api/rdv.service';
import { Medecin } from '../../models/entity/medecin';
import { Patient } from '../../models/entity/patient';
import { Prestation } from '../../models/entity/prestation';
import { Rdv } from '../../models/entity/rdv';
import { RdvType, StatusRdv } from '../../models/enum/rdv.enum';
import { RdvRequest } from '../../models/request/rdv.request';
import { ReactiveService } from '../../services/reactive.service';
import { DateService } from './../../services/date.service';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'rdv-dialog',
  templateUrl: './rdv-dialog.component.html',
  styleUrls: ['./rdv-dialog.component.scss']
})
export class RdvDialogComponent extends BaseDialogComponent implements OnInit {

  IconName = IconName;
  rdv: Rdv;
  date: Date;
  rdvForm: FormGroup;
  types: OptionModel<RdvType>[] = [{ value: RdvType.RDV, label: 'rdv.rdv-type' }, { value: RdvType.EVENT, label: 'rdv.event-type' }];
  prestations: Prestation[] = [];
  prestations$: Observable<OptionModel<string>[]>;
  patients$: Observable<OptionModel<string>[]>;
  medecin: Medecin;
  patient: Patient;

  constructor(protected override injector: Injector, private rdvService: RdvService,
    private medecinService: MedecinService, private reactiveService: ReactiveService) {
    super(injector);
    this.rdv = this.data?.content?.rdv;
    this.date = this.data?.content?.date;
    this.patient = this.data?.content?.patient;
    this.medecin = this.medecinService.connectedMedecin.getValue();

    this.patients$ = medecinService.getPatients()
      .pipe(take(1), map(resp => resp.data.map(p => ({
        value: p.id,
        label: UserService.getFullName(p),
        data: p
      }))));

    this.prestations$ = medecinService.getPrestations({ filter: this.getPrestationFilter() })
      .pipe(take(1), map(resp => resp.data.map(p => ({
        value: p.id,
        label: p.motif?.libelle,
        data: p
      }))));
  }

  ngOnInit(): void {
    this.rdvForm = new FormGroup({
      type: new FormControl(this.types[0].value),
      patient: new FormControl({ value: this.getSlectedPatient(), disabled: !!this.rdv?.id }, Validators.required),
      prestation: new FormControl(this.getSlectedPrestation(), Validators.required),
      motif: new FormControl({ value: this.rdv?.motif, disabled: true }),
      debut: new FormControl(this.rdv?.debut ?? this.date, Validators.required),
      fin: new FormControl(this.rdv?.fin ?? this.date),
      lieu: new FormControl(this.rdv?.lieu),
      details: new FormControl(this.rdv?.details)
    });

    this.typeControl.valueChanges.pipe(takeUntil(this.destroy), debounceTime(200)).subscribe(type => {
      if (type === RdvType.RDV) {
        this.patientControl.addValidators(Validators.required);
        this.prestationControl.addValidators(Validators.required);
        this.patientControl.enable();
        this.prestationControl.enable();
        this.motifControl.clearValidators();
        this.motifControl.disable();
        this.finControl.clearValidators();
        this.finControl.disable();
      } else {
        this.patientControl.clearValidators();
        this.prestationControl.clearValidators();
        this.patientControl.disable();
        this.prestationControl.disable();
        this.motifControl.addValidators(Validators.required);
        this.motifControl.enable();
        this.finControl.addValidators(Validators.required);
        this.finControl.enable();
      }
    });

    if (this.rdv && !(this.rdv.patient || this.patient)) {
      this.typeControl.patchValue(this.types[1].value);
      this.motifControl.enable();
      this.motifControl.addValidators(Validators.required);
    }
  }

  getSlectedPatient() {
    const patient = this.rdv?.patient ?? this.patient;
    return patient ? {
      value: patient.id,
      label: UserService.getFullName(patient),
      data: patient
    } : null;
  }

  getSlectedPrestation() {
    return this.rdv?.prestation ? {
      value: this.rdv.prestation.id,
      label: this.rdv.prestation.motif?.libelle ?? this.rdv?.motif,
      data: this.rdv.prestation
    } : null;
  }

  getPrestationFilter() {
    const option = FilterUtils.generateFilter(SearchCondition.AND);
    const utils = new FilterUtils();
    option.filters.push(utils.booleenFilter('actif').equal(true).get());
    return option;
  }

  get typeControl() {
    return this.rdvForm.get('type');
  }

  get patientControl() {
    return this.rdvForm.get('patient');
  }

  get prestationControl() {
    return this.rdvForm.get('prestation');
  }

  get motifControl() {
    return this.rdvForm.get('motif');
  }

  get finControl() {
    return this.rdvForm.get('fin');
  }

  get isRdv() {
    return this.typeControl.value === RdvType.RDV || !!this.rdv?.patient?.id;
  }

  save() {
    if (this.rdvForm.valid) {
      const data = this.rdvForm.getRawValue();
      const debut = DateService.trunkHours(data.debut);
      const fin = this.isRdv ? DateService.addMinToDate(debut, this.medecin.duree_rdv) : DateService.trunkHours(data.fin);
      const request: RdvRequest = {
        id: this.rdv?.id ?? null,
        status: this.rdv?.status ?? StatusRdv.ACCEPTED,
        debut,
        fin,
        lieu: this.isRdv ? this.medecin?.etablissement?.libelle : data.lieu,
        prestation: this.isRdv ? data.prestation?.value : null,
        motif: this.isRdv ? data.prestation?.label : data.motif,
        couleur: this.isRdv ? data.prestation?.data?.couleur : null,
        tarif: this.isRdv ? data.prestation?.data?.tarif : null,
        patient: this.isRdv ? (data.patient?.value ?? this.rdv?.patient?.id) : null,
        details: data.details,
        medecin: this.medecin?.id
      };
      this.reactiveService.call(this.rdvService.save(this.typeControl.value, request), resp => this.close({ content: resp.data }), true);
    }
  }
}
