import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName } from '@san/tools';
import { Rdv } from '../../models/entity/rdv';

@Component({
  selector: 'rdv-synthese-events-bloc',
  templateUrl: './synthese-events-bloc.component.html',
  styleUrls: ['./synthese-events-bloc.component.scss']
})
export class SyntheseEventsBlocComponent {
  @Input() events: Rdv[] = [];
  @Input() header: string;
  @Input() icon: IconName = IconName.CALENDAR_DAY;
  @Output() rdvChange: EventEmitter<void> = new EventEmitter<void>();
  IconName = IconName;
}
