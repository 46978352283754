import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DefaultImage, FileDestination } from "../models/interfaces/file.interface";
import { FileService } from "../services/file.service";

@Pipe({ name: 'toImage' })
export class ImageFilePipe implements PipeTransform {

  transform(fileData: Buffer): string {
    return FileService.decodeFile(fileData) ?? DefaultImage;
  }
}

@Pipe({ name: 'previewFile' })
export class PreviewFilePipe implements PipeTransform {

  constructor(private fileService: FileService, private sanitizer: DomSanitizer) {
  }

  transform(url: string, id: string | number, location: FileDestination): string | SafeUrl {
    return url?.length ? this.sanitizer.bypassSecurityTrustUrl(`${this.fileService.getBaseUrl()}${location}/${id}/${url}`) : DefaultImage;
  }
}

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    return FileService.convertSize(size);
  }
}
