<div *ngIf="rdv" (dblclick)="showRdv(rdv)" class="appointment pointer font-bold" fxLayout="row"
  fxLayoutAlign="space-between center" fxLayoutGap="15px">
  <div class="text-primary">{{rdv.debut | displayDate:true}}</div>
  <div fxFlex.gt-xs="30%" fxFlex.gt-md="40%">{{user | fullName}}</div>
  <div [title]="rdv.tarif | displayPrice">{{rdv.motif}}</div>
  <div class="app-spacer"></div>
  <div>
    <ng-container *ngIf="isRdv">

      <san-icon *ngIf="canCancel" title="{{'rdv.action.CANCELED' | translate}}" [name]="IconName.CLOSE"
        (onclick)="editStatus(StatusRdv.CANCELED)" classes="text-warn"></san-icon>

      <ng-container *ngIf="pending && isMedecin">
        <san-icon title="{{'rdv.action.ACCEPTED' | translate}}" [name]="IconName.CHECK_CIRCLE"
          (onclick)="editStatus(StatusRdv.ACCEPTED)" classes="text-primary"></san-icon>
        <san-icon title="{{'rdv.action.REJECTED' | translate}}" [name]="IconName.CLOSE_CIRCLE"
          (onclick)="editStatus(StatusRdv.REJECTED)" classes="text-warn"></san-icon>
      </ng-container>

    </ng-container>

    <san-icon *ngIf="!isRdv && isMedecin" title="{{'rdv.action.supprimer' | translate}}" [name]="IconName.DELETE"
      (onclick)="delete()" classes="text-warn"></san-icon>

    <san-icon *ngIf="canEdit" title="{{'shared.modifier' | translate}}" [name]="IconName.EDIT" (onclick)="edit()"
      classes="text-primary"></san-icon>
  </div>
</div>