import { Component, Input } from '@angular/core';
import { Medecin } from './../../../models/entity/medecin';

@Component({
  selector: 'rdv-medecin-info',
  templateUrl: './medecin-info.component.html',
  styleUrls: ['./medecin-info.component.scss']
})
export class MedecinInfoComponent {

  @Input() medecin: Medecin;
  @Input() editable: boolean = false;

}
