import { Injectable } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TraductorService {

  constructor(private translateService: TranslateService) {
  }

  langaugeChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange.asObservable();
  }

  fetch(key: string, interpolateParams?: unknown | undefined) {
    return this.translateService.stream(key, interpolateParams);
  }

  translate(key: string, interpolateParams?: unknown | undefined) {
    return this.translateService.instant(key, interpolateParams);
  }

  changeLanguage(lang: string) {
    this.translateService.setDefaultLang(lang);
    this.translateService.reloadLang(lang);
  }

}
