import { Injectable } from "@angular/core";
import { catchError, from, map, of, take } from "rxjs";
import { AccountType } from './../../shared/models/enum/user-shared.enum';
import { NavigationService } from './../../shared/services/navigation.service';
import { UserService } from './../../shared/services/user.service';

@Injectable({ providedIn: 'root' })
export class TokenVerificationService {

  constructor(private userService: UserService, private navigationService: NavigationService) {
  }

  canActivateRoute(isMedecin: boolean) {
    const url = this.loginUrl(isMedecin);
    if (this.connected(isMedecin)) {
      return true;
    } else if (!this.navigationService.currentRouter.navigated) {
      return from(this.userService.refreshToken())
        .pipe(
          take(1),
          map(res => {
            if (res?.token && this.connected(isMedecin)) {
              return true;
            } else {
              this.navigationService.navigate(url);
              return false;
            }
          }),
          catchError(() => {
            this.navigationService.navigate(url);
            return of(false);
          }));
    } else {
      this.navigationService.navigate(url);
      return false;
    }
  }

  private connected(isMedecin: boolean) {
    if (isMedecin) {
      return this.userService.medecinIsConnected;
    } else {
      return this.userService.patientIsConnected;
    }
  }

  private loginUrl(isMedecin: boolean): string {
    const type = isMedecin ? AccountType.MEDECIN : AccountType.PATIENT;
    return `/account/${type}/login`;
  }

}
