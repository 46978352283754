
export enum Sexe {
  MASCULIN = 'M',
  FEMININ = 'F'
}

export enum AccountType {
  MEDECIN = 'medecin',
  PATIENT = 'patient'
}
