import { DialogService } from './../../../shared/services/dialog.service';
import { Component } from '@angular/core';
import { IconName } from '@san/tools';
import { AppConf } from '../../../shared/models/interfaces/app-conf.interface';
import { AppconfService } from '../../../shared/services/app-conf.service';
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'rdv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  conf: AppConf;
  IconName: typeof IconName = IconName;

  constructor(private confService: AppconfService, private dialogService: DialogService) {
    this.conf = this.confService.getAppConf();
  }


  openAboutDialog() {
    this.dialogService.open(AboutComponent, { width: '700px' });
  }
}
