<san-modal [header]="data?.header" (closeModal)="close()">
  <div content>
    <san-check-list [globalSelection]="true" [items]="this.data?.content" idControl="rdv-checklist"
      (selectedItemsChange)="handleSelected($event)"></san-check-list>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon idControl="cancel-checklist-btn" (onclick)="close()" [icon]="IconName.CLOSE" color="warn"
      label="shared.fermer"></san-button-icon>
    <san-button-icon idControl="validate-checklist-btn" [icon]="IconName.CHECK" color="primary" (onclick)="validate()"
      label="shared.valider"></san-button-icon>
  </div>
</san-modal>