<san-bloc [header]="header" [icon]="icon">
  <div content>
    <div *ngIf="rdv" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div>
        <img alt="Photo Patient" class="user-photo picture" rdvImageLoader
          [src]="patient.photo | previewFile:patient.id:FileDestination.PATIENT">
        <div class="info small-padding">
          <div><label>{{'core.user.numero' | translate}}</label> : <span>{{patient.numero | displayValue}}</span></div>
          <div><label>{{'account.name' | translate}}</label> : <span>{{patient.nom | displayValue}}</span></div>
          <div><label>{{'account.firstname' | translate}}</label> : <span>{{patient.prenoms | displayValue}}</span>
          </div>
          <div><label>{{'account.date-naissance' | translate}}</label> : <span>{{patient.date_naissance |
              displayDate}}</span></div>
          <div><label>{{'account.sexe' | translate}}</label> : <span>{{patient.sexe | displayValue}}</span></div>
          <div><label>{{'account.poids' | translate}}</label> : <span>{{patient.poids | displayValue}}</span></div>
          <div><label>{{'account.taille' | translate}}</label> : <span>{{patient.taille | displayValue}}</span></div>
          <div><label>{{'account.sanguin' | translate}}</label> : <span>{{patient.sanguin | displayValue}}</span></div>
          <div><label>{{'account.contact' | translate}}</label> : <span>{{patient.email| displayValue}}</span></div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <div>
          <label class="text-accent font-bold">{{'rdv.motif' | translate}}</label>
          <div>{{rdv.motif}}</div>
        </div>
        <div *ngIf="rdv.details?.length">
          <label class="text-accent font-bold">{{'rdv.details' | translate}}</label>
          <div>{{rdv.details}}</div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <san-button-icon id="notes-patient" label="rdv.notes" [icon]="IconName.FILE_WAVE"></san-button-icon>
          <san-button-icon color="accent" id="documents-patient" label="rdv.documents"
            [icon]="IconName.FILE"></san-button-icon>
          <san-button-icon *ngIf="cancelable" id="cancel-rdv" label="shared.annuler" [icon]="IconName.CLOSE"
            color="warn"></san-button-icon>
        </div>
      </div>
    </div>
    <div class="empty" *ngIf="!rdv" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <san-icon [clickable]="false" [name]="IconName.WARNING" size="2xl" color="accent"></san-icon>
      <div>{{'rdv.aucunElement' | translate}}</div>
    </div>
  </div>
</san-bloc>