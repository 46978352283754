import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenVerificationService } from '../services/token.verification.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivatePatientRoute {

  constructor(private tokenVerificationService: TokenVerificationService) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.tokenVerificationService.canActivateRoute(false);
  }
}
