import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiResponse } from "../models/dto/response.dto";
import { StatusCode } from "../models/dto/status-code.model";
import { LoaderService } from "./loader.service";
import { NotificationService } from "./notifaction.service";

@Injectable({
  providedIn: "root"
})
export class ReactiveService {

  loading: boolean = false;

  constructor(public notificationService: NotificationService, private loaderService: LoaderService) {
  }

  call<T>(ws: Observable<ApiResponse<T>>, nextFunction?: (value: ApiResponse<T>) => void, showSuccess?: boolean) {
    this.loading = true;
    ws.pipe(take(1)).subscribe({
      next: (res: ApiResponse<T>) => {
        if (res.status === StatusCode.OK) {
          if (showSuccess) {
            this.notificationService.success(`core.error.${res.status}`);
          }
          if (nextFunction) {
            nextFunction(res);
          }
        } else {
          this.loaderService.removeLoaders();
        }
        this.loading = false;
      },
      error: () => this.loading = false
    });
  }


}
