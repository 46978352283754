import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SearchParam } from '@san/tools';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { ApiResponse } from '../models/dto/response.dto';
import { Patient } from "../models/entity/patient";
import { FileResponse } from '../models/interfaces/file.interface';
import { EmailRequest, NotificationRequest, PasswordRequest } from "../models/interfaces/user.interfaces";
import { PatientEnvironment, PatientRequest } from "../models/request/patient.request";
import { ApiBaseService } from './api-base.service';
import { Rdv } from '../models/entity/rdv';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends ApiBaseService {

  connectedPatient: BehaviorSubject<Patient> = new BehaviorSubject<Patient>(null);

  constructor(override http: HttpClient) {
    super(http, 'patients');
  }

  /**
   * Modifie les infos d'un patient
   * @param patient
   * @returns
   */
  edit(patient: PatientRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(this.baseUrl, patient);
  }

  /**
   * Renvoie un patient
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Patient>> {
    return this.http.post<ApiResponse<Patient>>(`${this.baseUrl}/find`, { id, relations });
  }

  /**
   * Pagine des patient
   * @param param
   * @returns
   */
  paginate(param?: SearchParam): Observable<ApiResponse<Patient[]>> {
    return this.http.post<ApiResponse<Patient[]>>(`${this.baseUrl}/list/paginate`, param);
  }

  /**
   * Recherche des patient
   * @param param
   * @returns
   */
  search(param?: SearchParam): Observable<ApiResponse<Patient[]>> {
    return this.http.post<ApiResponse<Patient[]>>(`${this.baseUrl}/list/full`, param);
  }

  /**
   * Charge l'env du patient connecte
   * @returns
   */
  environnement(): Observable<ApiResponse<PatientEnvironment>> {
    return this.http.get<ApiResponse<PatientEnvironment>>(`${this.baseUrl}/get/environnement`)
      .pipe(take(1), filter(env => !!env?.data?.user?.id), tap((rep: ApiResponse<PatientEnvironment>) => this.connectedPatient.next(rep.data.user)));
  }

  /**
   * Modifie le mot de passe d'un patient
   * @param request
   * @returns
   */
  updatePassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-password`, request);
  }

  updateNotificationSettings(request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-notification-settings`, request);
  }

  /**
   * Modifie le mail d'un patient
   * @param request
   * @returns
   */
  updateEmail(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-email`, request);
  }

  /**
   * Change la photo de profil du patient
   * @param data
   * @returns
   */
  updatePhoto(data: FormData): Observable<ApiResponse<FileResponse>> {
    return this.http.put<ApiResponse<FileResponse>>(`${this.baseUrl}/update-photo`, data);
  }

  deletePhoto(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-photo/${id}`);
  }

  /**
   * Verifie si ID correspond a celui du patient connecte
   * @param id
   * @returns
   */
  isConnectedPatient(id: string): boolean {
    return id === this.connectedPatient.getValue()?.id;
  }

  getEvents(param: SearchParam, forExport: boolean = false): Observable<ApiResponse<Rdv[]>> {
    const query = forExport ? `?export=true` : '';
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/rdv/list${query}`, param);
  }

}
