import { AfterViewInit, Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, debounceTime, filter, fromEvent, map } from 'rxjs';
import { ApplicationService } from './shared/services/application.service';
import { LoaderService } from './shared/services/loader.service';

@Component({
  selector: 'rdv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  routeLoading$: Observable<boolean>;

  constructor(private appService: ApplicationService, public loaderService: LoaderService, private router: Router) {
    this.routeLoading$ = this.router.events.pipe(
      filter(routeEvents => [
        NavigationStart,
        NavigationEnd,
        NavigationError,
        NavigationCancel
      ].some(construct => routeEvents instanceof construct)),
      map((events) => events instanceof NavigationStart));
  }

  ngAfterViewInit(): void {
    // Ecoute les changements de taille de fenêtre
    fromEvent(window, 'resize').pipe(debounceTime(500)).subscribe(evt => this.appService.resizingWindow.next(evt));
  }
}
