export enum StatusRdv {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  TERMINATED = 'TERMINATED',
  CANCELED = 'CANCELED'
}

export enum RdvType {
  RDV = 'rdv',
  EVENT = 'event'
}

export enum RdvStep {
  DATE = 1,
  RAISON = 2,
  RESUME = 3
}
