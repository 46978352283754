import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { VerificationData } from '../models/interfaces/user.interfaces';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  public static toBase64(value: string): string {
    return Buffer.from(value).toString('base64');
  }

  public static toHex(value: string): string {
    return Buffer.from(value).toString('hex');
  }

  public static fromBase64(value: string): string {
    return Buffer.from(value, 'base64').toString();
  }

  public static fromHex(value: string): string {
    return Buffer.from(value, 'hex').toString();
  }

  /**
   * Encode une donnee
   * @param data
   * @param key
   * @returns
   */
  public static encode(data: string, key: string): string {
    const obj = { value: `${CryptoService.toBase64(data)}#.${CryptoService.toBase64(CryptoService.toHex(key))}` };
    return CryptoService.toBase64(CryptoService.toHex(JSON.stringify(obj)));
  }

  /**
   * Decode un token
   * @param token
   * @param key
   * @returns
   */
  public static decode(token: string, key: string) {
    const b64 = CryptoService.fromBase64(token);
    const obj = JSON.parse(CryptoService.fromHex(b64));
    if (obj.value?.length) {
      const parts = obj.value.split('#.');
      return CryptoService.fromBase64(parts[1]) === CryptoService.toHex(key) ? CryptoService.fromBase64(parts[0]) : null;
    }
    return null;
  }

  public static decodeAccountActionToken(token: string): { date: Date, data: VerificationData } {
    try {
      const info = jwtDecode(token) as any;
      return { ...info, data: JSON.parse(CryptoService.fromBase64(info.data)) };
    } catch (error) {
      return null;
    }
  }
}
