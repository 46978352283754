import { Pipe, PipeTransform } from "@angular/core";
import { DateService } from "../services/date.service";
import { ObjectUtility } from "../utils/object.utils";

@Pipe({ name: 'displayValue' })
export class DisplayValuePipe implements PipeTransform {

  transform(value: unknown): string {
    return value ? value as string : '-';
  }

}

@Pipe({ name: 'longDate' })
export class LongDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {
  }

  transform(value: Date | string, hours: boolean = false): string {
    if (value) {
      return this.dateService.longDate(new Date(value), hours);
    }
    return '-';
  }
}

@Pipe({ name: 'displayDate' })
export class DisplayDatePipe implements PipeTransform {

  transform(value: Date | string, hours?: boolean, seconds?: boolean): string {
    if (value) {
      return DateService.format(new Date(value), hours, seconds);
    }
    return '-';
  }

}

@Pipe({ name: 'displayHour' })
export class DisplayHourPipe implements PipeTransform {

  transform(value: Date | string, seconds?: boolean): string {
    if (value) {
      return DateService.formatHours(new Date(value), seconds);
    }
    return '-';
  }

}

@Pipe({ name: 'displayPrice' })
export class DisplayPricePipe implements PipeTransform {

  transform(price: number): string {
    return ObjectUtility.formatPrice(price);
  }

}

@Pipe({ name: 'displayList' })
export class DisplayListPipe implements PipeTransform {

  transform(values: any[], field: string = 'libelle', max: number = 3): string {
    if (!values?.length) {
      return '-';
    } else {
      let displayed = values.slice(0, max);
      if (field?.length) {
        displayed = displayed.map(d => d[field]);
      }
      max = values.length - max;
      return displayed.join(', ') + (max > 0 ? `, +${max}...` : '');
    }
  }

}
