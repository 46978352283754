import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, catchError, from, map, of, take } from 'rxjs';
import { NavigationService } from '../../shared/services/navigation.service';
import { UserService } from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAccountRoute {

  constructor(private userService: UserService, private navigationService: NavigationService) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.connectedUser) {
      this.navigationService.navigate(this.userService.dashboardLink);
      return false;
    } else if (!this.navigationService.currentRouter.navigated) {
      return from(this.userService.refreshToken(false))
        .pipe(
          take(1),
          map(res => {
            if (res?.token && this.userService.connectedUser) {
              this.navigationService.navigate(this.userService.dashboardLink);
              return false;
            }
            return true;
          }),
          catchError(() => of(true)));
    } else {
      return true;
    }
  }
}
