<div [isLoading]="loading" fxLayout="column" fxLayoutAlign="start center">
  <p class="font-bold text-wrap text-info center">{{'rdv.click-message' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
    <san-icon (onclick)="prev()" [disabled]="!canGoBack" [name]="IconName.ANGLE_LEFT" color="primary"></san-icon>
    <div *ngFor="let period of periods;" fxLayout="column" fxLayoutAlign="start center">
      <span class="long-date">{{period.date | longDate}}</span>
      <p class="badge pointer font-bold" [class.hidden]="!more && i > 5" [title]="p|longDate:true"
        (click)="clickOnTime.next(p)" *ngFor="let p of period.periods; let i=index;">
        {{p|displayHour}}
      </p>
    </div>
    <san-icon (onclick)="next()" [name]="IconName.ANGLE_RIGHT" color="primary"></san-icon>
  </div>
  <p *ngIf="showMoreBtn">
    <san-button (onclick)="toggleMoreTimes()" id="more-less-times" [label]="moreBtnTitle"></san-button>
  </p>
  <p *ngIf="emptyPeriods" class="font-bold text-accent text-wrap center">
    {{'rdv.aucune-disponibilite' | translate}}
  </p>
</div>